<template>
  <span
    class="clocking-column"
    :class="caption ? 'caption' : 'box'"
    :style="'vertical-align: top;'"
  >
    <span v-for="item of items" :key="item.index" :style="style">
      <span v-if="caption">{{ item.label }}</span>
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'ClockingColumns',
  mixins: [BackendMixin],
  props: {
    clockingColumns: Array,
    caption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    clockingColumns: function() {},
  },
  computed: {
    items() {
      return this.clockingColumns.map(
        (elt, index) => {
          return { label: elt, index: index, }
        }
      )
    },
    style() {
      let percent = 0
      if (this.clockingColumns.length) {
        percent = Math.floor(82 / this.clockingColumns.length)
      }
      return {
        width: '' + percent + '%',
        'max-width': '50px',
        height: this.caption ? '20px' : '25px',
        border: 'solid 1px #000',
        display: 'inline-block',
        'margin-left': '1px',
        'text-align': 'center',
        'font-size': '9px',
      }
    },
  },
  methods: {
  },
}
</script>
<style lang="less">
.clocking-column.box {
}
</style>
