import { SeanceInscription } from '@/types/youth.ts'
import { CafModel } from '@/types/reports'
import { diffTimes } from './time'

export function calculateClockingDuration(inscription: SeanceInscription, model: CafModel): number {
  let clocking = true
  let duration = 0
  let arrivedAt = inscription.arrivedAt || (model ? model.seanceStartsAt : null)
  let leftAt = inscription.leftAt || (model ? model.seanceEndsAt : null)
  let arrivedAt2 = inscription.arrivedAt2 || (model ? model.seanceStartsAt2 : null)
  let leftAt2 = inscription.leftAt2 || (model ? model.seanceEndsAt2 : null)
  let arrivedAt3 = inscription.arrivedAt3
  let leftAt3 = inscription.leftAt3
  const seance = inscription.seance
  let lunch = false
  if (seance.morning && seance.afternoon) {
    lunch = seance.lunch
    if ((arrivedAt2 === null) && (leftAt === null)) {
      // Si pas de pointage "midi" alors prend le pointage journée entière
      lunch = true
    }
  }
  if (lunch) {
    // Journée entière
    if (arrivedAt === null) {
      // Dans le cas d'une inscription en journée avec absence le matin
      arrivedAt = arrivedAt2
    }
    if (leftAt2 === null) {
      // Dans le cas d'une inscription en journée avec absence l'après-midi
      leftAt2 = leftAt
    }
    clocking = (arrivedAt !== null) && (leftAt2 !== null)
    if (clocking) {
      duration = diffTimes(leftAt2, arrivedAt)
    }
  } else {
    if (seance.morning) {
      let clocking1 = (arrivedAt !== null) && (leftAt !== null)
      if (clocking1) {
        duration += diffTimes(leftAt, arrivedAt)
      } else {
        clocking = false
      }
    }
    if (seance.afternoon) {
      let clocking2 = (arrivedAt2 !== null) && (leftAt2 !== null)
      if (clocking2) {
        duration += diffTimes(leftAt2, arrivedAt2)
      } else {
        clocking = false
      }
    }
    if (seance.evening) {
      let clocking3 = (arrivedAt3 !== null) && (leftAt3 !== null)
      if (clocking3) {
        duration += diffTimes(leftAt3, arrivedAt3)
      } else {
        clocking = false
      }
    }
  }
  return clocking ? (Math.round(100 * duration / 3600) / 100) : 0
}

export function calculateRealDuration(inscription: SeanceInscription, model: CafModel|null): number {
  if (!model) {
    return 0
  }
  const seance = inscription.seance
  let clockingDuration = calculateClockingDuration(inscription, model)
  switch (+model.convention.number) {
    case 0:
      return 0
    case 1:
    case 2:
    case 3:
    case 4:
      if (inscription.absence) {
        return 0
      } else {
        return clockingDuration || +seance.duration
      }
    case 5:
    case 6:
    case 7:
    case 14:
      if (inscription.absence) {
        return 0
      } else {
        return clockingDuration || +model.duration
      }

    case 8:
    case 9:
    case 10:
    case 12:
    case 13:
      if (inscription.absence) {
        return 0
      } else {
        return +model.duration
      }
    case 11:
      return 0
  }
  return 0
}

export function calculatePaidDuration(inscription: SeanceInscription, model: CafModel|null): number {
  if (!model) {
    return 0
  }
  if (inscription.absence && !inscription.invoiced) {
    return 0
  }
  const seance = inscription.seance
  let hourlyTariff = seance.seanceType.isHourlyTariff()
  let clockingDuration = calculateClockingDuration(inscription, model)
  switch (+model.convention.number) {
    case 0:
      return 0
    case 1:
      return (hourlyTariff && clockingDuration) ? clockingDuration : seance.duration
    case 2:
      return +model.duration
    case 3:
    case 4:
      return Math.min(+model.duration, +seance.duration)
    case 5:
    case 6:
    case 13:
      return +seance.duration
    case 7:
    case 8:
    case 9:
    case 14:
      if (hourlyTariff) {
        return clockingDuration || +seance.duration
      } else {
        return +seance.duration
      }
    case 10:
    case 12:
      return +model.duration
    case 11:
      return 0
  }
  return 0
}
